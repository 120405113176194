import React from "react";
import "./main.css";
import programmer from "./images/programmer-3.gif";

function App() {
	return (
		<div>
			<div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3">
				<h5 className="my-0 mr-md-auto font-weight-normal">
					Portafoli<span className="font-accent">o</span>
				</h5>
				<nav className="my-2 my-md-0 mr-md-3">
					<a className="p-2 text-dark top-menu" href="#about">
						About
					</a>
					<a className="p-2 text-dark top-menu" href="#resume">
						Resume
					</a>
					<a className="p-2 text-dark top-menu" href="#projects">
						Projects
					</a>
					<a
						className="p-2 text-dark top-menu"
						href="mailto:jono.calvo@gmail.com"
					>
						Contact
					</a>
				</nav>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-xs-12 d-block d-md-none">
						<img src={programmer} className="img-fluid" />
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<div className="row">
							<div className="col-12" id="about">
								<h2>
									About <span className="font-accent">Me</span>{" "}
								</h2>
								<p className="subtitle-text">
									I am Jonathan Calvo, Software Engineer and Video Producer
									located in San Diego, CA.
								</p>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<h2>
									Dev <span className="font-accent">Experience</span>{" "}
								</h2>
								<p className="subtitle-text no-bottom-margin">Web Developer</p>
								<p className="general-text no-bottom-margin font-weight-bold">
									Konrad Group
								</p>
								<p className="general-text">Oct 2015 - Jan 2017</p>

								<p className="subtitle-text no-bottom-margin">
									Software Engineer
								</p>
								<p className="general-text no-bottom-margin font-weight-bold">
									Mentis IT/Freelance
								</p>
								<p className="general-text">Nov 2007 - Dec 2008</p>

								<p className="subtitle-text no-bottom-margin">
									Software Engineer
								</p>
								<p className="general-text no-bottom-margin font-weight-bold">
									Isthmus It
								</p>
								<p className="general-text">
									Jan 2007 - Jun 2007 / Jan 2005 - May 2005
								</p>

								<p className="subtitle-text no-bottom-margin">
									Software Engineer
								</p>
								<p className="general-text no-bottom-margin font-weight-bold">
									Codex 702
								</p>
								<p className="general-text">Nov 2005 - Dec 2006</p>
							</div>
							<div className="col-md-6">
								<p className="subtitle-text">
									Skills
									<br></br>
									<p className="oval-p">
										<span className="oval-btn">JS</span>
										<span className="oval-btn">React</span>
										<span className="oval-btn">Node</span>
									</p>
									<p className="oval-p">
										<span className="oval-btn">Sass</span>
										<span className="oval-btn">Bootstrap</span>
										<span className="oval-btn">SQL</span>
									</p>
									<p className="oval-p">
										<span className="oval-btn">MongoDB</span>
										<span className="oval-btn">Gulp</span>
										<span className="oval-btn">.Net</span>
									</p>
									<p className="oval-p">
										<span className="oval-btn">Json</span>
										<span className="oval-btn">Firebase</span>
										<span className="oval-btn">J2EE</span>
									</p>
									<p className="oval-p">
										<span className="oval-btn">GIT</span>
										<span className="oval-btn">Photoshop</span>
									</p>
								</p>
							</div>
						</div>
					</div>
					<div className="col-md-6 d-none d-md-block">
						<img src={programmer} className="img-fluid" />
					</div>
				</div>

				<div className="row">
					<div className="col-xs-12 padding-left-right" id="resume">
						<h2>
							My <span className="font-accent">Resume</span>{" "}
						</h2>
						<a
							target="_blank"
							href="https://drive.google.com/file/d/1YWXv7q8sLHsv1Ip8LkN1zIhBiNztEHC6/view?usp=sharing"
							className="btn btn-light"
						>
							<svg
								class="bi bi-file-text"
								width="3em"
								height="3em"
								viewBox="0 0 16 16"
								fill="currentColor"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									d="M4 1h8a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2V3a2 2 0 012-2zm0 1a1 1 0 00-1 1v10a1 1 0 001 1h8a1 1 0 001-1V3a1 1 0 00-1-1H4z"
									clip-rule="evenodd"
								/>
								<path
									fill-rule="evenodd"
									d="M4.5 10.5A.5.5 0 015 10h3a.5.5 0 010 1H5a.5.5 0 01-.5-.5zm0-2A.5.5 0 015 8h6a.5.5 0 010 1H5a.5.5 0 01-.5-.5zm0-2A.5.5 0 015 6h6a.5.5 0 010 1H5a.5.5 0 01-.5-.5zm0-2A.5.5 0 015 4h6a.5.5 0 010 1H5a.5.5 0 01-.5-.5z"
									clip-rule="evenodd"
								/>
							</svg>
						</a>
					</div>
				</div>
				<div className="row">
					<div
						className="col-xs-12 padding-left-right projects-section"
						id="projects"
					>
						<h2>
							Personal <span className="font-accent">Projects</span>{" "}
						</h2>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6 padding-left-right">
						<p className="subtitle-text">
							<span className="highlight">Ticket Tracker</span> <br></br>
							React Ticket Tracker is a 3-tier architecture (DB, Server, Client)
							responsive web app that allows companies to have a web tool where
							clients can create tickets and follow on comments and solutions
							for this tickets. The system allows to have 3 kind of users,
							Clients, Agents and Managers that can assign tickets to other
							Agents and manage users. The app uses{" "}
							<span className="highlight">
								JS, bcrypt, JWT, RESTful API and Mongodb
							</span>{" "}
							as the backend. Manages auth with email and password. This app
							uses{" "}
							<span className="highlight">
								React Hooks, React Router, Bootstrap, Sass and Gulp
							</span>
							.<br></br>
							<a
								target="_blank"
								href="https://react-tracker-client.netlify.app/"
								className="btn btn-light btn-live"
							>
								Live Demo
							</a>
						</p>
					</div>

					<div className="col-md-6 padding-left-right">
						<p className="subtitle-text">
							<span className="highlight">Contraction Timer</span> <br></br>
							This is a 3-tier architecture responsive react app that helps to
							keep track of the contractions of a pregnant person, so can know
							when is the best moment to go to the hospital to give birth. The
							app uses <span className="highlight">Firebase</span> as the
							backend. Manages auth with email and password. This app uses{" "}
							<span className="highlight">
								ChartJS, Material UI, Sass and Gulp
							</span>
							.<br></br>
							<a
								target="_blank"
								href="https://react-contraction-timer.web.app/"
								className="btn btn-light btn-live"
							>
								Live Demo
							</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default App;
